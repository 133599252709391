import { encrypt, decrypt } from './crypto';


export const safeStore = (key, value) => {
  const encryptedValue = encrypt(value);
  localStorage.setItem(key, encryptedValue);
};

export const safeRetrieve = (key) => {
  const encryptedValue = localStorage.getItem(key);
  return encryptedValue ? decrypt(encryptedValue) : '';
};


export const plainStore = (key, value) => {
  localStorage.setItem(key, value);
}

export const plainRetrieve = (key) => {
  const value = localStorage.getItem(key);
  return value ? value : '';
}

export const storageRemove = (key) => {
  localStorage.removeItem(key);
}
