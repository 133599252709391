import React from 'react';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import './MainActiveChat.css'

import CopyButton from '../CopyButton/CopyButton';
import { safeRetrieve, plainRetrieve } from '../../utils/safeStoring';

import SlAvatar from '@shoelace-style/shoelace/dist/react/avatar';
import SlSpinner from '@shoelace-style/shoelace/dist/react/spinner';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlIconButton from '@shoelace-style/shoelace/dist/react/icon-button';


function MainActiveChat ({ isWaitingRagu, activeChatMessages, setActiveChatMessages, onCallRagu, exerciseTitle, setActiveExerciseId, setActiveExerciseMessages, showActions=true }) {

    const handleRetry = (index) => {
        // slice chatMessages up to the user message before the selected index
        const chatBeforeIndex = activeChatMessages.slice(0, index);
        onCallRagu(chatBeforeIndex);
    }

    const handleUpvote = (index) => {
        const vote = activeChatMessages[index].vote;
        const newVote = 1 - Math.max(0, vote);
        handleCallVoter(index, newVote);
    }

    const handleDownvote = (index) => {
        const vote = activeChatMessages[index].vote;
        const newVote = -1 - Math.min(0, vote);
        handleCallVoter(index, newVote);
    }

    const handleCallVoter = (index, vote) => {
        const newChatMessages = [...activeChatMessages];
        newChatMessages[index] = {
            ...newChatMessages[index],
            vote: vote
        }
        setActiveChatMessages(newChatMessages);

        const now = new Date();
        // call voter API to store user feedback
        const apiUrl = process.env.REACT_APP_API_URL || '';
        fetch(`${apiUrl}/api/historian/v1/vote_message`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                api_key: safeRetrieve('APIKEY_v2'),
                datetime: now.toISOString(),
                selected_model: plainRetrieve('selectedModel') || 'gpt-4o-mini',
                chat_messages: newChatMessages.slice(0, index+1),
                vote: vote
            }),
        })
        .then((response) => {
            if (response.status === 200) {
                console.log(`Message ${index} voted ${vote}`);
            } else {
                console.log(`Error in vote_message response: ${response.status} - ${response.json()}`);
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });

    }
    
    return (

        <div className="main-active-chat">

            {exerciseTitle.length > 0 && (
                <div className="top-app-bar-chat">
                    <SlIconButton name="arrow-left-short" label="Back to exercise list" style={{ fontSize: "32px" }} onClick={() => {
                        setActiveExerciseId("");
                        setActiveExerciseMessages([]);
                    }} />
                    <span style={{ lineHeight: '1' }}>{exerciseTitle}</span>
                </div>
            )}

            <div className="chat-messages-container">
                {activeChatMessages.map((message, index) => {
                    if (message.role === 'user') {
                        return (
                            <div className="chat-message" style={{ backgroundColor: 'var(--secondary-50)' }} key={index}>
                                <SlAvatar label="User avatar" className="chat-avatar" />
                                <Markdown className="chat-message-text" remarkPlugins={[remarkGfm]}>
                                    {message.content}
                                </Markdown>
                            </div>
                        );
                    } else if (message.role === 'assistant') {
                        return (
                            <div className="chat-message" key={index}>
                                <SlAvatar image="/harambeans-avatar.svg" label="User avatar" className="chat-avatar image-avatar" />
                                <div className="chat-message-col">
                                    <Markdown className="chat-message-text" remarkPlugins={[remarkGfm]} >
                                        {message.content}
                                    </Markdown>
                                    {showActions && (!isWaitingRagu || (index !== activeChatMessages.length-1)) && (index !== 0) && (
                                        <div className="chat-message-actions">
                                            <CopyButton 
                                                textToCopy={message.content} 
                                            />
                                            <SlIcon 
                                                className="chat-message-action-btn" 
                                                name="arrow-repeat"
                                                onClick={() => handleRetry(index)}
                                            />
                                            <SlIcon 
                                                className="chat-message-action-btn" 
                                                name={message.vote === 1 ? "hand-thumbs-up-fill": "hand-thumbs-up"}
                                                style={{ color: message.vote === 1 ? "var(--success-color)" : "var(--secondary-300)"}}
                                                onClick={() => handleUpvote(index)}
                                            />
                                            <SlIcon 
                                                className="chat-message-action-btn" 
                                                name={message.vote === -1 ? "hand-thumbs-down-fill": "hand-thumbs-down"}
                                                style={{ color: message.vote === -1 ? "var(--error-color)" : "var(--secondary-300)"}}
                                                onClick={() => handleDownvote(index)}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}

                {activeChatMessages[activeChatMessages.length-1].role === 'user' && (
                    <SlSpinner className="waiting-spinner" />
                )}
            
            </div>
        </div>
    );
};

export default MainActiveChat;
