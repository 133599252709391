import React, { useEffect } from 'react';
import './ChatHistory.css'

import { getLocale } from '../../locales/getLocale';
import { sortDictionary } from '../../utils/utils';
import { safeRetrieve, plainRetrieve } from '../../utils/safeStoring';
import { formatDateTime } from '../../utils/formatDateTime';
import EmptyPage from '../../assets/images/empty-page-ravens.png';

import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlDropdown from '@shoelace-style/shoelace/dist/react/dropdown';
import SlMenu from '@shoelace-style/shoelace/dist/react/menu';
import SlMenuItem from '@shoelace-style/shoelace/dist/react/menu-item';


function ChatHistory ({ isSearchView, setIsSearchView, searchableHistory, setSearchableHistory, userChatHistory, setUserChatHistory, setActiveChatID, setActiveChatMessages }) {

    const notSaveHistory = (plainRetrieve('notSaveHistory') === 'true') || false;

    useEffect(() => {
        const sortedHistory = sortDictionary(searchableHistory, 'datetime');
        setSearchableHistory(sortedHistory);
    }, [isSearchView]);
    

    const handleGetChatByObjectID = (objectID) => {

        // set dummy values to launch spinner while waiting for results
        setActiveChatID('');
        setActiveChatMessages([{'role': 'user', 'content': ''}]);
        setIsSearchView(false);

        const apiUrl = process.env.REACT_APP_API_URL || '';
        fetch(`${apiUrl}/api/historian/v1/get_by_id`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                object_id: objectID
            }),
        })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.log(`Error in get_by_id response: ${response.status} - ${response.json()}`);
            }
        })
        .then((data) => {
            setActiveChatID(data.body.chatID);
            setActiveChatMessages(data.body.chatMessages);
            setIsSearchView(false);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    const handleDeleteChatByObjectID = (objectID, chatID) => {

        // set entry as deleted to trigger exit animation
        setSearchableHistory(prevHistory => ({
            ...prevHistory,
            [chatID]: {
              ...searchableHistory[chatID],
              deleted: true,
            },
        }));
        
        // once animation is complete proceed with actual deletion
        setTimeout(() => {
            // delete chat from internal state chat history (use chatID to delete by key)
            const { [chatID]: _1, ...newSearchableHistory } = searchableHistory;
            setSearchableHistory(newSearchableHistory);
            const { [chatID]: _2, ...newUserHistory } = userChatHistory;
            setUserChatHistory(newUserHistory);
        }, 300)
        
        // call endpoint to delete record from database too
        const apiKey = safeRetrieve('APIKEY_v2');
        const apiUrl = process.env.REACT_APP_API_URL || '';
        fetch(`${apiUrl}/api/historian/v1/delete_by_id?api_key=${apiKey}&object_id=${objectID}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
            if (response.status === 200) {
                console.log(`Chat with ID=${chatID} deleted from DB`);
            } else {
                console.log(`Error in back-end response: ${response.status} - ${response.json()}`);
            }
            })
        .catch((error) => {
            console.error('Error:', error);
        });

    }


    return (

        <div className={`chat-history-panel ${isSearchView ? 'visible' : ''}`}>
            {/* if user is not saving history, remind him with a small disclaimer */}
            {notSaveHistory && (
                <span style={{ color: 'var(--secondary-300)', marginBottom: '8px' }}>
                    {getLocale('remindNotSaveHistory')}
                </span>
            )}
            {Object.entries(searchableHistory).map(([key, chatHistoryEntry]) => {
                return (
                    <div 
                        key={key} 
                        className={`chat-history-entry ${chatHistoryEntry.deleted ? 'deleted' : ''}`}
                    >
                        <div 
                            className="chat-history-entry-left"
                            onClick={() => handleGetChatByObjectID(chatHistoryEntry.objectID)}
                        >
                            <SlIcon name="chat-right-text-fill" className="chat-history-icon leading" />
                            <div className="chat-history-entry-main">
                                <div className="chat-history-entry-first-row">
                                    <span className="chat-history-entry-title">{chatHistoryEntry.title}</span>
                                    <span className="chat-history-entry-date">{formatDateTime(chatHistoryEntry.datetime)}</span>
                                </div>
                                <span className="chat-history-entry-text">
                                    {chatHistoryEntry.firstQuery}
                                </span>
                            </div>
                        </div>

                        <SlDropdown hoist style={{ margin: 'auto 0' }}>
                            <SlIcon 
                                className="chat-history-icon trailing" 
                                name="three-dots-vertical"
                                slot="trigger"
                            />
                            <SlMenu>
                                <SlMenuItem 
                                    disabled
                                    // TODO: onClick={() => {}}
                                >
                                    <SlIcon slot="prefix" name="share-fill" />
                                    {getLocale('button.share')}
                                </SlMenuItem>
                                <SlMenuItem 
                                    className="dropdown-option-delete"
                                    onClick={() => handleDeleteChatByObjectID(chatHistoryEntry.objectID, chatHistoryEntry.chatID)}
                                >
                                    <SlIcon slot="prefix" name="trash3-fill" />
                                    {getLocale('button.delete')}
                                </SlMenuItem>
                            </SlMenu>
                        </SlDropdown>

                    </div>
                )
            })}
            
            {Object.keys(searchableHistory).length === 0 && (
                <div className="chat-history-panel-empty">
                    <img src={EmptyPage} alt="Empty history graphic" style={{ height: "50%" }}/><br />
                    <span>{getLocale('emptyPage')}</span>
                </div>
            )}
        </div>
    );
};

export default ChatHistory;
