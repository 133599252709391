import React, { useState } from 'react';
import './LoginDialogV2.css';

import { getLocale } from '../../locales/getLocale';

import FullLogo from '../../assets/images/full-logo.svg'
import RocketMail from '../../assets/anims/RocketMail/RocketMail';

import SlDialog from '@shoelace-style/shoelace/dist/react/dialog';
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';


function LoginDialogV2 ({isOpen}) {

    const [email, setEmail] = useState('');
    const [voucher, setVoucher] = useState('');
    const [waitingForResponse, setWaitingForResponse] = useState(false);
    const [showVoucherField, setShowVoucherField] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isEmailSent, setIsEmailSent] = useState(false);


    const handleLogin = () => {
        if (showVoucherField) {
            handleVoucherCheck();
        } else {
            handleMailCheck();
        }
    }


    const handleMailCheck = () => {
        setWaitingForResponse(true);

        // call the API to sign-in the user
        const apiUrl = process.env.REACT_APP_API_URL || '';
        const url = `${apiUrl}/api/warden/v1/user_sign_in?mail_address=${email}`;
        fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.warning(`Error in back-end response: ${response.status} - ${response.json()}`);
            }
        })
        .then((data) => {
            if (data.body) {
                console.log('User was already registered.')
                setIsEmailSent(true);
                setWaitingForResponse(false);
            } else {
                console.log('New user. Requesting voucher...')
                setShowVoucherField(true);
                setWaitingForResponse(false);
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }
    

    const handleVoucherCheck = () => {
        setWaitingForResponse(true);

        // call the sign-up API to add new user
        const apiUrl = process.env.REACT_APP_API_URL || '';
        const url = `${apiUrl}/api/warden/v1/user_sign_up?mail_address=${email}&voucher=${voucher}`;
        fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.warning(`Error in back-end response: ${response.status} - ${response.json()}`);
            }
        })
        .then((data) => {
            if (data.body) {
                console.log('Sent confirmation e-mail to create new user.')
                setIsEmailSent(true);
                setWaitingForResponse(false);
            } else {
                console.log('Incorrect voucher', data.message);
                setWaitingForResponse(false);
                if (data.message === 'voucher-already-redeemed') {
                    setErrorMessage('Voucher già riscattato');
                } else {
                    setErrorMessage('Codice voucher non valido');
                }
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }
    

    return (
        <>
        <SlDialog 
            className="login-dialog"
            open={isOpen} 
            noHeader
            onSlRequestClose={(event) => {event.preventDefault();}}  // prevent closing
        >
            <img className='logo'
                src={FullLogo}
                alt="Loghia Logo"
            />


            {!isEmailSent ? (

                <>
                <p className='login-tagline'>{getLocale('loginDialogV2.tagline')}</p>
                <p>{getLocale('loginDialogV2.ctaMail')}</p>

                <SlInput 
                    autofocus
                    placeholder={getLocale('loginDialogV2.placeholderMail')}
                    disabled={showVoucherField}
                    type="email"
                    value={email}
                    onSlInput={(e) => setEmail(e.target.value)}
                >
                    <SlIcon name="envelope" slot="prefix"></SlIcon>
                </SlInput>

                {showVoucherField && (
                    <>
                        <p>
                            {getLocale('loginDialogV2.ctaVoucher1')}
                            <SlTooltip 
                                content={getLocale('loginDialogV2.ctaVoucherTip')}
                                style={{ '--max-width': '192px' }}
                                hoist
                            >
                            <u>{getLocale('loginDialogV2.ctaVoucher2')}</u>
                            </SlTooltip>
                        </p>
                        <SlInput
                            placeholder={getLocale('loginDialogV2.placeholderVoucher')}
                            help-text={getLocale('loginDialogV2.helpTextVoucher')}
                            value={voucher}
                            onSlInput={(e) => setVoucher(e.target.value)}
                        >
                            <SlIcon name="gift" slot="prefix"></SlIcon>
                        </SlInput>
                    </>
                )}

                {errorMessage && (errorMessage.length > 0) && (
                    <div className='error-message'>
                        <SlIcon name="exclamation-diamond"></SlIcon>
                        <span>{errorMessage}</span>
                    </div>
                )}

                <SlButton  
                    className='login-button'
                    variant="primary" 
                    loading={waitingForResponse}
                    disabled={(email.length === 0) || (showVoucherField && voucher.length === 0)}
                    onClick={() => handleLogin()}
                >
                    {getLocale('loginDialogV2.loginBtn')}
                </SlButton>
                </>
            
            ) : (

                <div className='sent-email-container'>
                    <h3>{getLocale('loginDialogV2.sentEmail1')}</h3>
                    <RocketMail />
                    <p>{getLocale('loginDialogV2.sentEmail2')}<br/><strong>{email}</strong></p>
                </div>
            
            )}
            

            <small className='ask-support'>
                {getLocale('loginDialogV2.askSupport')}<br/>
                <a href={`mailto:${getLocale('loginDialogV2.supportMail')}`}>{getLocale('loginDialogV2.supportMail')}</a>
            </small>

        </SlDialog>
        </>
    );
};

export default LoginDialogV2;
