import React, { useState } from 'react';
import './CopyButton.css';

import SlIcon from '@shoelace-style/shoelace/dist/react/icon';

const CopyButton = ({ textToCopy, btnLabel="" }) => {

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    try {
      // create tmp element to copy text
      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;
      const container = document.getElementById('copy-button-container');
      container.appendChild(textarea);
      textarea.focus();
      textarea.select();
      document.execCommand('copy');
      container.removeChild(textarea);
      console.log('Copied!');
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);  // duration for the tick icon to stay visible
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };


  return (
    <div id='copy-button-container' onClick={handleCopy} className={`copy-btn ${copied ? 'copied' : ''}`}>
      <SlIcon name="copy" className={`copy-icon ${copied ? 'animate-out' : 'animate-in'}`} />
      <SlIcon name="check-lg" className={`copy-icon ${copied ? 'animate-in' : 'animate-out'}`} />

      {btnLabel.length > 0 && (
        <span className={`copy-btn-label`}>
          {btnLabel}
        </span>
      )}
    </div>
  );
};

export default CopyButton;
