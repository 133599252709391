import React, { useEffect } from 'react';

import { safeStore, plainStore } from '../../utils/safeStoring';


function MagicLink() {


    const getUserInfo = (apiKey) => {
        // call API to get user name from API key
        const apiUrl = process.env.REACT_APP_API_URL || '';
        const url = `${apiUrl}/api/warden/credentials/user_info?api_key=${apiKey}`;
        fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.warning(`Error in back-end response: ${response.status} - ${response.json()}`);
            }
        })
        .then((data) => {
            if (data.body) {
                plainStore('userName', data.body.user_name);
                const firstName = data.body.first_name
                const firstNameClean = firstName.match(/^[a-zA-Z0-9]+/);
                if (firstNameClean) {
                    plainStore('firstName', firstNameClean[0]);
                }
            }
            // Redirect to the base URL
            window.location.href = '/';
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }


    useEffect(() => {
        // Check if the URL contains a fragment (hash) part
        const hash = window.location.hash;

        if (hash) {
            // Extract the key from the hash
            const login_token = hash.replace('#', '');

            // call API to retrieve the actual API_KEY
            const apiUrl = process.env.REACT_APP_API_URL || '';
            const url = `${apiUrl}/api/warden/v1/decode_token?token=${login_token}`;
            fetch(url, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    console.warning(`Error in back-end response: ${response.status} - ${response.json()}`);
                }
            })
            .then((data) => {
                if (data.body) {
                    console.log('Retrieved API_KEY. Storing it in local storage...');
                    safeStore('APIKEY_v2', data.body);
                    // retrieve user name and first name
                    getUserInfo(data.body);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        }
    }, []);


    return (
        <></>
);
}

export default MagicLink;
