import React from 'react';
import './SettingsDialog.css';

import RadioButton from '../RadioButton/RadioButton';
import { getLocale } from '../../locales/getLocale';
import { plainRetrieve, plainStore, storageRemove } from '../../utils/safeStoring';

import SlDialog from '@shoelace-style/shoelace/dist/react/dialog';
import SlSwitch from '@shoelace-style/shoelace/dist/react/switch';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';


function SettingsDialog ({ isOpen, setIsOpen, onChangeAppTheme, onChangeDyslexiaFont }) {

    const appTheme = plainRetrieve('appTheme') || 'system';
    const notSaveHistory = (plainRetrieve('notSaveHistory') === 'true') || false;
    const useDyslexiaFont = (plainRetrieve('useDyslexiaFont') === 'true') || false;

    const handleNotSaveHistory = (checked) => {
        // remember that switch checked means that history must be saved 
        plainStore('notSaveHistory', !checked);
    }

    const handleSignOut = () => {
        // remove all elements from local storage
        storageRemove('APIKEY_v2');
        storageRemove('userName');
        storageRemove('firstName');
        storageRemove('notSaveHistory');
        storageRemove('useDyslexiaFont');
        // reload page to apply changes
        window.location.reload();
    }

  return (
    <SlDialog 
        label={getLocale('SettingsDialog.title')}
        open={isOpen} 
        onSlRequestClose={() => setIsOpen(false)}
        style={{ 'color': 'var(--on-surface)' }}
    >

        <div className="settings-dialog-body">

            <div className="settings-dialog-apptheme">
                {getLocale('SettingsDialog.optionTheme')}

                <RadioButton 
                    initActiveValue={appTheme} 
                    onChangeActiveValue={onChangeAppTheme} 
                />
            </div>

            <div className="settings-dialog-row">
                {getLocale('SettingsDialog.optionHistory')}
                <SlSwitch checked={!notSaveHistory} onSlChange={(e) => handleNotSaveHistory(e.target.checked)} />
            </div>

            <div className="settings-dialog-row">
                {getLocale('SettingsDialog.optionDyslexia')}
                <SlSwitch checked={useDyslexiaFont} onSlChange={(e) => onChangeDyslexiaFont(e.target.checked)} />
            </div>

            <div className='sign-out' onClick={handleSignOut} >
                <SlIcon name="door-open"></SlIcon>
                {getLocale('SettingsDialog.signOut')}
            </div>

            <small className='ask-support' style={{ margin: '0' }}>
                {getLocale('loginDialogV2.askSupport')}<br/>
                <a href={`mailto:${getLocale('loginDialogV2.supportMail')}`}>{getLocale('loginDialogV2.supportMail')}</a>
            </small>
        
        </div>
    
    </SlDialog>
  );
};


export default SettingsDialog;
