import React, { useState } from 'react';
import './RadioButton.css';

import { getLocale } from '../../locales/getLocale';

import SlIcon from '@shoelace-style/shoelace/dist/react/icon';

const RadioButton = ({ initActiveValue, onChangeActiveValue }) => {

    const [activeValue, setActiveValue] = useState(initActiveValue);

    const handleChangeValue = (value) => {
        setActiveValue(value);
        onChangeActiveValue(value);
    }

    return (
        <div className="radio-btn-group">
            <div 
                className={`radio-btn-element ${(activeValue === 'system') ? 'radio-btn-element-selected' : ''}`}
                onClick={() => {handleChangeValue('system')}}
            >
                <SlIcon slot="prefix" name="circle-half" />
                {getLocale('SettingsDialog.optionThemeSystem')}
            </div>
            <div 
                className={`radio-btn-element ${(activeValue === 'light') ? 'radio-btn-element-selected' : ''}`}
                onClick={() => {handleChangeValue('light')}}
            >
                <SlIcon slot="prefix" name="sun-fill" />
                {getLocale('SettingsDialog.optionThemeLight')}
            </div>
            <div 
                className={`radio-btn-element ${(activeValue === 'dark') ? 'radio-btn-element-selected' : ''}`}
                onClick={() => {handleChangeValue('dark')}}
            >
                <SlIcon slot="prefix" name="moon-fill" />
                {getLocale('SettingsDialog.optionThemeDark')}
            </div>
        </div>
    );
};

export default RadioButton;
