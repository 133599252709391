import React from 'react';
import './RocketMail.css';

const RocketMail = () => {
  return (
    <div className="rocket-mail-container">
      <svg className="email-svg" viewBox="0 0 60 40">
        <path d="M2 2L30 20L58 2M2 2H58V38H2V2Z" />
      </svg>
      <div className="exhaust-container">
        <div id="exhaust-line1" className="exhaust-line"></div>
        <div id="exhaust-line2" className="exhaust-line"></div>
        <div id="exhaust-line3" className="exhaust-line"></div>
      </div>
    </div>
  );
};

export default RocketMail;
