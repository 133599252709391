import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './App.css';

import { getLocale } from './locales/getLocale';
import { searchAlgoliaHits } from './utils/db_algolia';
import { sortDictionary } from './utils/utils';
import { safeRetrieve, plainRetrieve, plainStore } from './utils/safeStoring';

import HorizontalLogo from './assets/images/horizontal-logo.svg';
import CompactLogo from './assets/images/compact-logo.svg';
import availableModels from './assets/models/available_models';
import LoginDialogV2 from './components/LoginDialogV2/LoginDialogV2';
import SettingsDialog from './components/SettingsDialog/SettingsDialog';
import UserInput from './components/UserInput/UserInput';
import MainNewChat from './components/MainNewChat/MainNewChat';
import MainActiveChat from './components/MainActiveChat/MainActiveChat';
import ChatHistory from './components/ChatHistory/ChatHistory';
import TabExercises from './components/TabExercises/TabExercises';
import ShareDialog from './components/ShareDialog/ShareDialog';

import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlAlert from '@shoelace-style/shoelace/dist/react/alert';
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlIconButton from '@shoelace-style/shoelace/dist/react/icon-button';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import SlTab from '@shoelace-style/shoelace/dist/react/tab';
import SlTabGroup from '@shoelace-style/shoelace/dist/react/tab-group';
import SlTabPanel from '@shoelace-style/shoelace/dist/react/tab-panel';

import { useMediaQuery } from "react-responsive";


import '@shoelace-style/shoelace/dist/themes/light.css';
import '@shoelace-style/shoelace/dist/themes/dark.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';

// override with custom styles
import './StylesDecameron.css';

setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.15.0/cdn/');


function App() {

  const isTabletScreen = useMediaQuery({ maxWidth: 1224 });
  const isMobileScreen = useMediaQuery({ maxWidth: 700 });


  const [isDarkMode, setIsDarkMode] = useState(false);
  const [apiKey, setApiKey] = useState(safeRetrieve('APIKEY_v2'));

  const [showLoginDialog, setShowLoginDialog] = useState(safeRetrieve('APIKEY_v2').length === 0);
  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [showModelBar, setShowModelBar] = useState(true);
  const [isSearchView, setIsSearchView] = useState(false);

  const [isWaitingRagu, setIsWaitingRagu] = useState(false);

  // const [selectedModel, setSelectedModel] = useState(Cookies.get('selectedModel') || 'gpt-4o-mini');
  const [selectedModel, setSelectedModel] = useState('gpt-4o-mini');  // disable model selection and force gpt-4o-mini
  const exerciseModel = 'exercise-gpt-4o-mini';

  const [activeChatID, setActiveChatID] = useState(uuidv4());
  const [activeChatMessages, setActiveChatMessages] = useState([]);
  const [userChatHistory, setUserChatHistory] = useState({});
  const [searchableHistory, setSearchableHistory] = useState({});

  const [exerciseInfo, setExerciseInfo] = useState({});
  const [activeExerciseId, setActiveExerciseId] = useState("");
  const [activeExerciseMessages, setActiveExerciseMessages] = useState([]);

  const [activeTab, setActiveTab] = useState('free-chat');

  // update value of activeTab at every tab change
  useEffect(() => {
    const tabGroup = document.getElementById('navigation-tabs');

    if (tabGroup) {
      const handleTabShow = (event) => {
        setActiveTab(event.detail.name);
      };

      tabGroup.addEventListener('sl-tab-show', handleTabShow);

      // Cleanup event listener on unmount
      return () => {
        tabGroup.removeEventListener('sl-tab-show', handleTabShow);
      };
    }
  }, []);


  useEffect(() => {
    // check if selected model is in list of available models
    if (!availableModels.hasOwnProperty(selectedModel)) {
      selectedModel = 'gpt-4o-mini';  // default value
      setSelectedModel(selectedModel);
    }
    // change the model color variable
    document.documentElement.style.setProperty('--model-color', availableModels[selectedModel]['color']);
  }, [selectedModel]);

  const apiCallError = useRef(null);

  const systemDarkMode = useMediaQuery({ query: '(prefers-color-scheme: dark)' });
  
  // Initialize dark mode state  
  useEffect(() => {
    const appThemeStored = plainRetrieve('appTheme');
    handleAppTheme(appThemeStored);
  }, [systemDarkMode]);

  // initialize font
  useEffect(() => {
    const useDyslexiaFont = (plainRetrieve('useDyslexiaFont') === 'true') || false;
    handleDyslexiaFont(useDyslexiaFont);
  }, []);

  // when activeChatMessages changes switch back to the FreeChat Tab
  useEffect(() => {
    const tabGroup = document.getElementById('navigation-tabs');
    tabGroup.show('free-chat');
    // setActiveTab('free-chat');
  }, [activeChatMessages]);
  

  const handleAppTheme = (theme) => {
    if (theme === 'light') {
      setIsDarkMode(false);
    } else if (theme === 'dark') {
      setIsDarkMode(true);
    } else {
      setIsDarkMode(systemDarkMode);
    }
    plainStore('appTheme', theme);
  };

  const handleDyslexiaFont = (checked) => {
    if (checked) {
          // set dyslexia friendly font family
          document.documentElement.style.setProperty('--font-family', 'var(--dyslexia-font-family)');
      } else {
          // set default font family
          document.documentElement.style.setProperty('--font-family', 'var(--default-font-family)');
      }
      plainStore('useDyslexiaFont', checked);
  }


  const retrieveExerciseInfo = (chatHistory) => {
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const url = `${apiUrl}/api/librarian/v1/get_exercises_list?api_key=${apiKey}`;
    fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    })
    .then((response) => {
        if (response.status === 200) {
            return response.json();
        } else {
            console.log(`Error in back-end response: ${response.status} - ${response.json()}`);
        }
    })
    .then((data) => {
        console.log('Retrieved exercise info from back-end.');
        let exerciseInfo = data.body;
        // if an exercise is in the user chat history its 'new' field should be set to false
        Object.keys(exerciseInfo).forEach(key => {
          exerciseInfo[key].new = !(key in chatHistory);
        });
        console.log('exerciseInfo:', exerciseInfo);
        setExerciseInfo(exerciseInfo);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
  }

  // retrieve chat history of user from DB
  useEffect(() => {

    const apiUrl = process.env.REACT_APP_API_URL || '';
    fetch(`${apiUrl}/api/historian/v1/get_user_all`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          api_key: apiKey
      }),
    })
    .then((response) => {
      if (response.status === 200) {
          return response.json();
      } else {
          console.log(`Error in back-end response: ${response.status} - ${response.json()}`);
      }
    })
    .then((data) => {
      console.log("Retrieved user chat history from DB");
      const chatHistory = data.body;
      setUserChatHistory(chatHistory);
      console.log("chatHistory:", chatHistory);

      // retrieve esercises Info from backend
      retrieveExerciseInfo(chatHistory);
    })
    .catch((error) => {
      console.error('Error:', error);
    });

  }, [apiKey]);


  const selectSearchableChats = () => {
    // remove from user history all the chats with a searchableText empty field
    console.log("userChatHistory:", userChatHistory);
    const notExcerciseHistory = Object.fromEntries(
      Object.entries(userChatHistory).filter(
        ([key, value]) => value.isSearchable
      )
    );
    console.log("notExcerciseHistory:", notExcerciseHistory);
    setSearchableHistory(notExcerciseHistory);
  }


  const handleCallRagu = (chatMessages) => {

    // determine if it is an exercise chat to decide which state will be updated
    const isExerciseChat = chatMessages[0]?.role === 'assistant';

    if (isExerciseChat) { setActiveExerciseMessages(chatMessages); } else { setActiveChatMessages(chatMessages); }

    // check if librarian is already running
    if (isWaitingRagu) {
      return
    }

    // check if input message has been entered
    if (chatMessages.length === 0) {
      return
    }

    setIsWaitingRagu(true);

    // get user question from last message in chat history
    const userQuestion = chatMessages[chatMessages.length - 1].content;
    // chat history for API is all messages except for the last one
    const previousMessages = chatMessages.slice(0, chatMessages.length - 1);

    // call librarian API with inputs inserted by user
    const apiUrl = process.env.REACT_APP_API_URL || '';
    fetch(`${apiUrl}/api/librarian/v1/ask_ragu`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            api_key: apiKey,
            query: userQuestion,
            llm_model_name: isExerciseChat ? exerciseModel : selectedModel,  // for exercises use specific model to trigger Ragu behavior,
            chat_history: previousMessages
        }),
    })
    .then((response) => {
      if (response.status === 200) {
          return response.json();
      } else {
          console.log(`Error in back-end response: ${response.status} - ${response.json()}`);
          setIsWaitingRagu(false);
      }
    })
    .then((data) => {
      console.log("Consumption of LLM call", data.consumption);
      console.log("Relevant docs from LLM call", data.relevant_docs);
      // set response in chat history
      const newMessage = { role: 'assistant', content: data.body };
      if (isExerciseChat) {
        setActiveExerciseMessages((prevHistory) => [...prevHistory, newMessage]);
      } else {
        setActiveChatMessages((prevHistory) => [...prevHistory, newMessage]);
      }
      setIsWaitingRagu(false);
      // wait for states to fully update and then add to DB      
      // setTimeout(() => {
      //   callAddToHistory(inputMessageSent, selectedChannelSent, selectedClusterSent, data.body);        
      // }, 1000);
    })
    .catch((error) => {
      console.error('Error:', error);
      setIsWaitingRagu(false);
    });
  };
  

   const handleCallRaguStream = async (chatMessages) => {

    // determine if it is an exercise chat to decide which state will be updated
    const isExerciseChat = chatMessages[0]?.role === 'assistant';

    if (isExerciseChat) { setActiveExerciseMessages(chatMessages); } else { setActiveChatMessages(chatMessages); }

    // check if librarian is already running
    if (isWaitingRagu) {
      return
    }

    // check if input message has been entered
    if (chatMessages.length === 0) {
      return
    }

    setIsWaitingRagu(true);

    // scroll to bottom of the page
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });

    // get user question from last message in chat history
    const userQuestion = chatMessages[chatMessages.length - 1].content;
    // chat history for API is all messages except for the last one
    const previousMessages = chatMessages.slice(0, chatMessages.length - 1);

    // call librarian API with inputs inserted by user
    const apiUrl = process.env.REACT_APP_API_URL || '';
    var response = await fetch(`${apiUrl}/api/librarian/v1/ask_ragu_stream`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          api_key: apiKey,
          query: userQuestion,
          llm_model_name: isExerciseChat ? exerciseModel : selectedModel,  // for exercises use specific model to trigger Ragu behavior,
          chat_history: previousMessages
      })
    })

    var reader = response.body.getReader();
    var decoder = new TextDecoder('utf-8');

    const newMessage = { role: 'assistant', content: '', vote: 0 };
    chatMessages = [...chatMessages, newMessage];

    reader.read().then(function processResult(result) {
        if (result.done) {
          setIsWaitingRagu(false);
          const notSaveHistory = (plainRetrieve('notSaveHistory') === 'true') || false;
          if (!notSaveHistory) {
            // add new messages to DB and to chat History
            handleAddNewMessages(chatMessages, isExerciseChat);
          }
          return;
        }
        let chunk = decoder.decode(result.value);

        const lastMessageIndex = chatMessages.length - 1;
        chatMessages = [
          ...chatMessages.slice(0, lastMessageIndex),
          {
              ...chatMessages[lastMessageIndex],
              content: chatMessages[lastMessageIndex].content + chunk
          }
        ];
        if (isExerciseChat) { setActiveExerciseMessages(chatMessages); } else { setActiveChatMessages(chatMessages); }
        // scroll to bottom of the page
        window.scrollTo({ top: document.body.scrollHeight });

        return reader.read().then(processResult);
    });
  }


  const handleAddNewMessages = (chatMessages, isExerciseChat) => {

    const now = new Date();
    // stores in the database the last two messages from user and assistant
    const apiUrl = process.env.REACT_APP_API_URL || '';
    fetch(`${apiUrl}/api/historian/v1/add_messages`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          api_key: apiKey,
          chat_id: isExerciseChat ? activeExerciseId : activeChatID,
          datetime: now.toISOString(),
          chat_messages: chatMessages,
          make_it_searchable: !isExerciseChat  // exercise chats must not be searchable
      }),
    })
    .then((response) => {
      if (response.status === 200) {
          return response.json();
      } else {
          console.log(`Error in back-end response: ${response.status} - ${response.json()}`);
      }
    })
    .then((data) => {
      console.log("Added user/assistant messages to DB");
      // response from API is data to add in userChatHistory
      const newChatHistory = {
        ...userChatHistory,
        [data.body.chatID]: data.body
      };
      // re-sort history by datetime
      const sortedNewChatHistory = sortDictionary(newChatHistory, 'datetime');
      setUserChatHistory(sortedNewChatHistory);
    })
    .catch((error) => {
      console.error('Error:', error);
    });

  }

  
  const handleSearchInput = (searchQuery) => {

    // check if user deleted all input -> recover all user chat history
    if (searchQuery.length === 0) {
      selectSearchableChats();
    } else {
      // search in algolia DB using given query
      searchAlgoliaHits(searchQuery)
      .then((hits) => {
          // result of Algolia search is a list, convert to dictionary format
          const hitsDict = {};
          hits.forEach((item) => {
              hitsDict[item.chatID] = item;
          });
          setSearchableHistory(hitsDict);
      })
      .catch((error) => {
          console.error("Error handling Algolia search results:", error);
      });
    }
  
  }



  return (
    <div className={isDarkMode ? 'dark-mode sl-theme-dark sl-theme-dark-raven' : 'sl-theme-light sl-theme-raven'} style={{ display: 'flex', flex: '1 1 0%', width: 'inherit' }}>

      <div className="App">
        
        <div className="top-app-bar">
        
          <header className="App-header">

            {/* <SlDropdown>
              <SlButton slot="trigger" className="dropdown-model" size="medium" caret>
                <img src={availableModels[selectedModel]['icon']} alt={availableModels[selectedModel['name']]} slot="prefix" width={20} height={20} />
                {!isMobileScreen && (<span>{availableModels[selectedModel]['name']}</span>)}
              </SlButton>
              <SlMenu>
                {Object.entries(availableModels).map(([modelKey, modelData]) => (
                  <SlMenuItem 
                    key={modelKey}
                    style={{ backgroundColor: selectedModel === modelKey ? 'var(--secondary-50)' : 'var(--surface)' }}
                    onClick={() => {setSelectedModel(modelKey); Cookies.set('selectedModel', modelKey); setShowModelBar(true);}}
                  >
                    <img src={modelData.icon} alt={modelData.name} slot="prefix" width={20} height={20} />
                    {modelData.name}
                  </SlMenuItem>
                ))}
              </SlMenu>
            </SlDropdown> */}
            <img style={{ height: '40px' }}
                src={isMobileScreen ? CompactLogo : HorizontalLogo}
                alt="Harambeans Logo"
            />

            <div></div>  {/* empty div to correctly place header-actions to the right */}
            
            
            <div className="header-actions">

              {!isTabletScreen ? (
                <SlInput 
                  className={isSearchView ? "search-bar-input-active" : "search-bar-input"}
                  placeholder={getLocale('button.searchBar')}
                  clearable={isSearchView}
                  filled
                  onSlFocus={() => {setIsSearchView(true); selectSearchableChats();}}
                  onSlInput={(e) => handleSearchInput(e.target.value)}
                >
                  {isSearchView ? (
                    <SlIcon name="arrow-left" slot="prefix" className="back-btn" onClick={() => {setIsSearchView(false)}} />
                  ) : (
                    <SlIcon name="search" slot="prefix" />
                  )}
                </SlInput>
              ) : (
                <>
                {isSearchView ? (
                  <SlInput 
                    className="search-bar-input-active"
                    placeholder={getLocale('button.searchBar')}
                    clearable={isSearchView}
                    filled
                    onSlInput={(e) => handleSearchInput(e.target.value)}
                  >
                    <SlIcon name="arrow-left" slot="prefix" onClick={() => {setIsSearchView(false)}} />
                  </SlInput>
                ) : (
                  <SlTooltip content={getLocale('tooltip.searchBar')}>
                    <SlIconButton 
                      name="search" label="Search" 
                      onClick={() => {setIsSearchView(true); selectSearchableChats();}} 
                      // style={{ marginRight: 'auto' }}
                    />
                  </SlTooltip>
                )}
                </>
              )}

              <div className="btn-new-chat" onClick={() => {setActiveChatMessages([]); setActiveChatID(uuidv4());}}>
                <SlIcon name="plus-lg" style={{ fontSize: '20px' }} />
                {!isMobileScreen && (<span style={{ paddingBottom: '2px' }} >{getLocale('button.newChat')}</span>)}
              </div>

              <SlTooltip content={getLocale('tooltip.share')}>
                <SlIconButton name="share-fill" label="Share" onClick={() => setShowShareDialog(true)}
                  // disable button when no chat is open 
                  disabled={
                    ((activeTab === 'free-chat') && (activeChatMessages.length === 0))
                    || ((activeTab === 'exercises') && (activeExerciseMessages.length === 0))
                  }
                />
              </SlTooltip>

              <SlTooltip content={getLocale('tooltip.settings')}>
                <SlIconButton name="gear-fill" label="Settings" onClick={() => setShowSettingsDialog(true)} />
              </SlTooltip>

              {/* <SlTooltip content={getLocale('tooltip.changeUser')}>
                <SlAvatar 
                  className="user-avatar" 
                  label="User avatar" 
                  onClick={() => setShowLoginDialog(true)}
                />
              </SlTooltip> */}
            </div>

          </header>

          {/* <ModelBarMessage 
            showModelBar={showModelBar}
            setShowModelBar={setShowModelBar}
            selectedModel={selectedModel}
          /> */}
          
        </div>

        {/* <LoginDialog 
          isOpen={showLoginDialog} 
          setIsOpen={setShowLoginDialog} 
          setApiKey={setApiKey}
        /> */}
        <LoginDialogV2 
          isOpen={showLoginDialog} 
        />

        <SettingsDialog 
          isOpen={showSettingsDialog}
          setIsOpen={setShowSettingsDialog}
          onChangeAppTheme={handleAppTheme}
          onChangeDyslexiaFont={handleDyslexiaFont}
        />

        <ShareDialog 
          isOpen={showShareDialog}
          setIsOpen={setShowShareDialog}
          chatID={(activeTab === 'exercises') ? activeExerciseId : activeChatID}
          title={(activeExerciseId.length > 0) ? exerciseInfo[activeExerciseId].title : ""}
        />

        <div className="App-body">
          <div className="demo-content">

            <ChatHistory 
              isSearchView={isSearchView}
              setIsSearchView={setIsSearchView}
              searchableHistory={searchableHistory}
              setSearchableHistory={setSearchableHistory}
              userChatHistory={userChatHistory}
              setUserChatHistory={setUserChatHistory}
              setActiveChatID={setActiveChatID}
              setActiveChatMessages={setActiveChatMessages}
            />


            <SlTabGroup className="navigation-tabs" id="navigation-tabs">
                <SlTab slot="nav" panel="free-chat">
                  <div className="tab-title">
                    <SlIcon name="chat-dots-fill" />
                    {getLocale('Tabs.free-chat')}
                  </div>
                </SlTab>
                <SlTab slot="nav" panel="exercises">
                  <div className="tab-title">
                    <SlIcon name="pencil-fill" />
                    {getLocale('Tabs.exercises')}
                  </div>
                </SlTab>

                <SlTabPanel name="free-chat">
                  {(activeChatMessages.length === 0) ? (
                    <MainNewChat 
                      apiKey={apiKey}
                      activeChatID={activeChatID}
                      onCallRagu={handleCallRaguStream}
                    />
                  ) : (
                    <MainActiveChat
                      isWaitingRagu={isWaitingRagu}
                      activeChatMessages={activeChatMessages}
                      setActiveChatMessages={setActiveChatMessages}
                      onCallRagu={handleCallRaguStream}
                      exerciseTitle={""}
                    />
                  )}
                </SlTabPanel>

                <SlTabPanel name="exercises">
                  {(activeExerciseMessages.length === 0) ? (
                    <TabExercises 
                      exerciseInfo={exerciseInfo}
                      setExerciseInfo={setExerciseInfo}
                      setActiveExerciseId={setActiveExerciseId}
                      setActiveExerciseMessages={setActiveExerciseMessages}
                    />
                  ) : (
                    <MainActiveChat
                      isWaitingRagu={isWaitingRagu}
                      activeChatMessages={activeExerciseMessages}
                      setActiveChatMessages={setActiveExerciseMessages}
                      onCallRagu={handleCallRaguStream}
                      exerciseTitle={exerciseInfo[activeExerciseId].title}
                      setActiveExerciseId={setActiveExerciseId}
                      setActiveExerciseMessages={setActiveExerciseMessages}
                    />
                  )}
                
                </SlTabPanel>
              
              </SlTabGroup>
            

            <UserInput
              isWaitingRagu={isWaitingRagu}
              activeChatMessages={activeChatMessages}
              activeExerciseMessages={activeExerciseMessages}
              onCallRagu={handleCallRaguStream}
              activeTab={activeTab}
            />
          
          </div>
        </div>
      
      </div>

      <SlAlert ref={apiCallError} variant="danger" duration="3000" closable>
        <SlIcon slot="icon" name="exclamation-square" />
        Error during API call.
      </SlAlert>

    </div>
  );
}

export default App;
