import React, { useState } from 'react';
import './UserInput.css'

import TextareaAutosize from 'react-textarea-autosize';

import SlIconButton from '@shoelace-style/shoelace/dist/react/icon-button';

import { getLocale } from '../../locales/getLocale';

function UserInput ({ isWaitingRagu, activeChatMessages, activeExerciseMessages, onCallRagu, activeTab }) {
  
  const [inputText, setInputText] = useState('');
  
  const sendQuery = () => {
    // append the query to the chat history
    const newMessage = { role: 'user', content: inputText };
    let newChatMessages;
    if (activeTab === 'exercises') {
      newChatMessages = [...activeExerciseMessages, newMessage];
    } else {
      newChatMessages = [...activeChatMessages, newMessage];
    }
    // clear the input field
    setInputText('');
    // call the Librarian API
    onCallRagu(newChatMessages);
  }

  const handleChange = (event) => {
    setInputText(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      // Action to perform when Enter is pressed without shift
      sendQuery();
      event.preventDefault(); // Prevent default behavior (new line)
    }
  };

  return (
    <div className='user-input-row'>
    <TextareaAutosize 
        minRows={1}
        maxRows={9}
        value={inputText}
        placeholder={getLocale('userInputHint')}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        className="user-input-query"
    />
    <SlIconButton 
        className="user-input-send-btn" 
        name="send-fill" 
        label="Send message" 
        disabled={(inputText.length === 0) || isWaitingRagu}
        onClick={() => sendQuery()}
        style={{ backgroundColor: (inputText.length === 0 || isWaitingRagu) ? 'var(--secondary-100)' : 'var(--model-color)' }}
    />
    </div>
  );
};

export default UserInput;
