import React, { useState, useEffect } from 'react';
import './MainNewChat.css'

import websiteLogo from '../../assets/images/ravenigno-down.svg';
import { getLocale } from '../../locales/getLocale';
import { plainRetrieve } from '../../utils/safeStoring';

import SlIcon from '@shoelace-style/shoelace/dist/react/icon';


const shuffleArray = (array) => {
    return array
        .map((item) => ({ item, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ item }) => item);
};


function MainNewChat ({ apiKey, activeChatID, onCallRagu }) {

    const [firstName, setFirstName] = useState(plainRetrieve('firstName') || getLocale('MainNewChat.defaultUser'));
    const [examples, setExamples] = useState(getLocale('MainNewChat.examples').slice(0, 4));
    
    
    useEffect(() => {
        // Define a function that gets the first name from local storage
        const getFirstName = () => {
          const name = plainRetrieve('firstName') || getLocale('MainNewChat.defaultUser');
          setFirstName(name);
        };
    
        // Set a timeout to delay the execution of getFirstName
        const timeoutId = setTimeout(getFirstName, 2000);
    
        // Cleanup function to clear the timeout if apiKey changes again before 2 seconds
        return () => clearTimeout(timeoutId);
        
      }, [apiKey]); // Dependency array to re-run the effect when apiKey changes
    

    // everytime a new chat is opened take 4 new examples at random
    useEffect(() => {
        // take 4 random elements from the list of examples
        const allExamples = getLocale('MainNewChat.examples');
        const randomExamples = shuffleArray(allExamples).slice(0, 4);
        setExamples(randomExamples);
    }, [activeChatID]); // Dependency array to re-run the effect if and only if activeChatID changes


    const sendQuery = (query) => {
        // append the query to the chat history
        const newChatMessages = [{ role: 'user', content: query }];
        // call the Librarian API
        onCallRagu(newChatMessages);
    }

    
    return (
        <div className="main-new-chat">
            <div className="row-greet">
                {/* <img src={websiteLogo} alt='website-logo' width={56} height={56} style={{ marginBottom: '24px' }} /> */}
                <span className="text-greet">{getLocale('MainNewChat.greet')} {firstName}</span>
            </div>
            <span className="text-call-to-action">{getLocale('MainNewChat.callToAction')} <strong>{getLocale('bookName')}</strong></span>
            {/* <div className="text-book-name">
                <SlIcon name="journal-bookmark-fill" style={{ marginTop: '10px', minWidth: '36px' }} />
                <span><strong>{getLocale('bookName')}</strong></span>
            </div> */}
            <span className="examples-intro">{getLocale('MainNewChat.examplesIntro')}</span>
            <div className="examples-row">
                {examples.map((example, index) => (
                    <div className="btn-example" key={index} onClick={(e) => sendQuery(e.target.textContent)}>
                        <span>{example.query}</span>
                        <SlIcon name={example.icon} className="btn-example-icon" />
                    </div>
                ))}
            </div>
            <div className="disclaimer-row">
                <SlIcon name="info-circle" style={{ minWidth: '14px' }} />
                <span>{getLocale('MainNewChat.disclaimer')}</span>
            </div>
        </div>
    );
};

export default MainNewChat;
