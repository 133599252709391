import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import App from './App';
import MagicLink from './pages/MagicLink/MagicLink';
import SharePage from './pages/SharePage/SharePage';

const AppRouter = () => (
  <Router>
    <Routes>
      <Route path="/magic-link" element={<MagicLink />} />
      <Route path="/share" element={<SharePage />} />
      <Route path="/" element={<App />} />
    </Routes>
  </Router>
);

export default AppRouter;
