import React, { useState, useEffect } from 'react';
import { useMediaQuery } from "react-responsive";
import './SharePage.css';

import MainActiveChat from '../../components/MainActiveChat/MainActiveChat';
import { plainRetrieve, safeRetrieve } from '../../utils/safeStoring';
import { formatDateTime } from '../../utils/formatDateTime';

import LogoTagline from '../../assets/images/logo-tagline.png'
import QRCode from '../../assets/images/qr-code.png'


function SharePage() {

    const [isDarkMode, setIsDarkMode] = useState(false);
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    
    const systemDarkMode = useMediaQuery({ query: '(prefers-color-scheme: dark)' });

    const apiKey = safeRetrieve('APIKEY_v2');
    
    // set dark mode according to system settings
    useEffect(() => {

        const applyDarkMode = (theme) => {
            if (theme === 'light') {
                setIsDarkMode(false);
            } else if (theme === 'dark') {
                setIsDarkMode(true);
            } else {
                setIsDarkMode(systemDarkMode);
            }
        };

        const theme = plainRetrieve('appTheme') || 'system';
        applyDarkMode(theme);
    }, [systemDarkMode])


    const getChatByObjectID = (objectID) => {
        
        const apiUrl = process.env.REACT_APP_API_URL || '';
        fetch(`${apiUrl}/api/historian/v1/get_by_id`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                object_id: objectID
            }),
        })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.log(`Error in get_by_id response: ${response.status} - ${response.json()}`);
            }
        })
        .then((data) => {
            console.log('Retrieved chat history from DB');
            setTitle(data.body.title);
            setDate(data.body.datetime);
            setChatMessages(data.body.chatMessages);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }


    useEffect(() => {
        // Check if the URL contains a fragment (hash) part
        const hash = window.location.hash;
        if (hash) {
            // Extract the key from the hash
            const chatID = hash.replace('#', '');
            // call API to retrieve chat information
            getChatByObjectID(chatID);
        }
    }, []);


    return (
        <div className={isDarkMode ? 'dark-mode sl-theme-dark sl-theme-dark-raven' : 'sl-theme-light sl-theme-raven'} style={{ display: 'flex', flex: '1 1 0%', width: 'inherit' }}>
            <div className='share-page'>
                <div className='share-content'>

                    <div className='share-header'>
                        {title && title.length > 0 && (
                            <div className='share-title'>
                                {title}
                            </div>
                        )}

                        {date && date.length > 0 && (
                            <div className='share-date'>
                                <i>{formatDateTime(date, true)}</i>
                            </div>
                        )}
                    </div>

                    {chatMessages && chatMessages.length > 0 && (
                        <MainActiveChat
                            activeChatMessages={chatMessages}
                            exerciseTitle={""}
                            showActions={false}
                        />
                    )}
                </div>

                {/* show only for non-logged in users */}
                {chatMessages.length >0 && apiKey.length === 0 && (
                    <div className='share-footer' onClick={() => {window.location.href = '/';}}>
                        <img src={LogoTagline} alt="Logo Tagline" height={128} />
                        <img src={QRCode} alt="Logo Tagline" height={128} />
                    </div>
                )}

            </div>
        </div>
    );
}

export default SharePage;
