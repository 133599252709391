import { format, isSameDay, isSameYear } from 'date-fns';
import { it } from 'date-fns/locale';


export const formatDateTime = (datetime, fullMonth=false) => {

    const monthFormat = fullMonth ? 'MMMM' : 'MMM';

    const date = new Date(datetime);
    const now = new Date();
    
    if (isSameDay(date, now)) {
        return format(date, 'HH:mm', { locale: it });
    } else if (isSameYear(date, now)) {
        return format(date, `d ${monthFormat}`, { locale: it });
    } else {
        return format(date, `d ${monthFormat} yyyy`, { locale: it });
    }
};
