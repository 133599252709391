import gpt35Icon from './gpt-35.svg';
import gpt4oMiniIcon from './gpt-4o-mini.svg';
import gpt4Icon from './gpt-4.svg';
import gpt4oIcon from './gpt-4o.svg';
import openaiWhiteIcon from './openai-white.svg';

import cohereIcon from './cohere.svg';
import cohereWhiteIcon from './cohere-white.svg'

import gemini15flashIcon from './gemini-15-flash.svg';
import gemini15proIcon from './gemini-15-pro.svg';
import geminiWhiteIcon from './gemini-white.svg';

import mistralIcon from './mistral.svg';
import mistralWhiteIcon from './mistral-white.svg';

import metaIcon from './meta.svg';
import metaWhiteIcon from './meta-white.svg';


const availableModels = {
    "gpt-35": {"name": "ChatGPT 3.5", "icon": gpt35Icon, "icon-white": openaiWhiteIcon, "color": "#1C7652"},
    "gpt-4o-mini": {"name": "ChatGPT 4o Mini", "icon": gpt4oMiniIcon, "icon-white": openaiWhiteIcon, "color": "#af274c"},
    "gpt-4": {"name": "ChatGPT 4", "icon": gpt4Icon, "icon-white": openaiWhiteIcon, "color": "#74297a"},
    "gpt-4o": {"name": "ChatGPT 4o", "icon": gpt4oIcon, "icon-white": openaiWhiteIcon, "color": "#972540"},
    "cohere-chat": {"name": "Cohere", "icon": cohereIcon, "icon-white": cohereWhiteIcon, "color": "#dc5235"},
    "gemini-15-flash": {"name": "Gemini 1.5 Flash", "icon": gemini15flashIcon, "icon-white": geminiWhiteIcon, "color": "#3164a6"},
    "gemini-15-pro": {"name": "Gemini 1.5 Pro", "icon": gemini15proIcon, "icon-white": geminiWhiteIcon, "color": "#2c40ad"},
    "mixtral-8x7b": {"name": "Mistral MoE", "icon": mistralIcon, "icon-white": mistralWhiteIcon, "color": "#d85623"},
    "llama-3-8b": {"name": "Llama 3 8B", "icon": metaIcon, "icon-white": metaWhiteIcon, "color": "#306ae4"},
    "llama-3-70b": {"name": "Llama 3 70B", "icon": metaIcon, "icon-white": metaWhiteIcon, "color": "#306ae4"}
}

export default availableModels