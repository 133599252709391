import React from 'react';
import './TabExercises.css'

import { getLocale } from '../../locales/getLocale';

import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlTag from '@shoelace-style/shoelace/dist/react/tag';
import SlBadge from '@shoelace-style/shoelace/dist/react/badge';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import SlSpinner from '@shoelace-style/shoelace/dist/react/spinner';


function TabExercises ({ exerciseInfo, setExerciseInfo, setActiveExerciseId, setActiveExerciseMessages }) {
    
    const handleStartChat = (exerciseId) => {
        // set selected exercise
        setActiveExerciseId(exerciseId);
        // create first message of the chat from the predefined question
        const firstMessage = {
            "role": "assistant",
            "content": exerciseInfo[exerciseId].text,
            "vote": 0
        };
        setActiveExerciseMessages([firstMessage]);
        // once open, the chat is no longer new
        setExerciseInfo({...exerciseInfo, [exerciseId]: {...exerciseInfo[exerciseId], new: false }});
    }

    const handleResumeChat = (exerciseId) => {
        // set selected exercise
        setActiveExerciseId(exerciseId);
        
        // set dummy values to launch spinner while waiting for results
        setActiveExerciseMessages([{'role': 'user', 'content': ''}]);
        
        const apiUrl = process.env.REACT_APP_API_URL || '';
        fetch(`${apiUrl}/api/historian/v1/get_by_id`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                object_id: exerciseId
            }),
        })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.log(`Error in get_by_id response: ${response.status} - ${response.json()}`);
            }
        })
        .then((data) => {
            setActiveExerciseMessages(data.body.chatMessages);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }


    return (
        <div className="tab-exercises">

            {Object.entries(exerciseInfo).map(([exerciseId, info]) => (
                <div key={exerciseId} className="row-exercise">

                    <div className="title-exercise">
                        <span style={{ fontSize: '14pt' }}>{info.title}</span>

                        <div className="tags-exercise">
                            {info.new && (
                                <SlBadge className="badge-new-item" pill pulse>
                                    {getLocale("Exercises.new")}
                                </SlBadge>
                            )}
                            {info.tags.map((tag, index) => (
                                <SlTag key={index} size="small" variant="primary" pill>{tag}</SlTag>
                            ))}
                        </div>
                    </div>

                    {info.new ? (
                        <div className="exercise-btn-group" >
                            <SlTooltip content={getLocale("tooltip.btnStart")}>
                                <div className="btn-exercise btn-primary" onClick={() => handleStartChat(exerciseId)} 
                                >
                                    <SlIcon name="play-fill" />
                                </div>
                            </SlTooltip>
                        </div>
                    ) : (
                        <div className="exercise-btn-group" >
                            <SlTooltip content={getLocale("tooltip.btnRestart")} >
                                <div className="btn-exercise btn-outlined" onClick={() => handleStartChat(exerciseId)}>
                                    <SlIcon name="arrow-clockwise" />
                                </div>
                            </SlTooltip>
                            <SlTooltip content={getLocale("tooltip.btnResume")}>
                                <div className="btn-exercise btn-secondary" onClick={() => handleResumeChat(exerciseId)} >
                                    <SlIcon name="play-fill" />
                                </div>
                            </SlTooltip>
                        </div>
                    )}

                </div>
            ))}

            {/* show spinner while loading exercises list */}
            {exerciseInfo && Object.keys(exerciseInfo).length === 0 && (
                <SlSpinner className="waiting-spinner" />
            )}

        </div>
    );
};

export default TabExercises;
