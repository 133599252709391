import React, { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import './ShareDialog.css';

import CopyButton from '../CopyButton/CopyButton';
import { getLocale } from '../../locales/getLocale';
import { safeRetrieve } from '../../utils/safeStoring';

import SlDialog from '@shoelace-style/shoelace/dist/react/dialog';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';


function ShareDialog ({ isOpen, setIsOpen, chatID, title="" }) {

    const [shareLink, setShareLink] = useState('');
    const [waitingForResponse, setWaitingForResponse] = useState(false);


    const handleGenerateLink = () => {
        
        setWaitingForResponse(true);

        const apiUrl = process.env.REACT_APP_API_URL || '';
        fetch(`${apiUrl}/api/historian/v1/share_chat`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                api_key: safeRetrieve('APIKEY_v2'),
                chat_id: chatID,
                title: title
            }),
        })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.warn(`Error in share_chat response: ${response.status} - ${response.json()}`);
            }
        })
        .then((data) => {
            setShareLink(`${window.location.origin}/share#${data.body}`);
            setWaitingForResponse(false);
        })
        .catch((error) => {
            setWaitingForResponse(false);
            console.error('Error:', error);
        });
    
    }


    return (
        <SlDialog 
            label={getLocale('ShareDialog.title')}
            open={isOpen} 
            onSlRequestClose={() => {setIsOpen(false); setShareLink('');}}
            style={{ 'color': 'var(--on-surface)' }}
        >

            <div className="share-dialog-body">

                <div className="share-dialog-disclaimer">
                    {getLocale('ShareDialog.disclaimer')}
                </div>

                {shareLink.length === 0 ? (
                    <SlButton  
                        className='login-button'
                        variant="primary" 
                        loading={waitingForResponse}
                        onClick={() => handleGenerateLink()}
                    >
                        <SlIcon name="link-45deg" slot="prefix" style={{ fontSize: '18px' }} />
                        {getLocale('ShareDialog.btnGenerate')}
                    </SlButton>
                ) : (
                    <>
                        <div className='share-dialog-link-container'>
                            <div className='share-dialog-link-text'>
                                {shareLink}
                            </div>
                            <div className='share-dialog-link-btn'>
                                <CopyButton 
                                    textToCopy={shareLink} 
                                    btnLabel={getLocale('ShareDialog.btnCopy')}
                                />
                            </div>
                        </div>

                        <QRCodeSVG value={shareLink} size={128} style={{ margin: '0 auto', backgroundColor: 'white', padding: '4px' }} />
                    </>
                )}

            </div>
        
        </SlDialog>
    );
};

export default ShareDialog;
