import algoliasearch from 'algoliasearch/lite'

import { plainRetrieve } from './safeStoring';


const algoliaApplicationID = process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
const algoliaApiKey = process.env.REACT_APP_ALGOLIA_API_KEY;
const algoliaIndexName = process.env.REACT_APP_ALGOLIA_INDEX_NAME;

const client = algoliasearch(algoliaApplicationID, algoliaApiKey);
const index = client.initIndex(algoliaIndexName);


export async function searchAlgoliaHits(searchQuery) {
    try {
        const userName = plainRetrieve('userName');
        const { hits } = await index.search(searchQuery, {
            filters: `userName:${userName}`,
            attributesToRetrieve: [
                "objectID",
                "chatID",
                "title",
                "datetime",
                "firstQuery"
            ],
            hitsPerPage: 50,
        });
        return hits;
    } catch (error) {
        console.error("Error calling Algolia search:", error);
        return [];
    }
}
